















































import { defineComponent, onMounted, reactive } from "@vue/composition-api";

export default defineComponent({
  props: {
    additives: {
      type: Array,
      required: false,
      default: () => [],
    },
    currency: {
      type: String,
      required: false,
      default: () => "EUR",
    },
    totalServicePrice: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  setup(props) {
    const state = reactive({
      totalLeftToPay: "" as any,
    });

    const totalLeftToPay = () => {
      state.totalLeftToPay = props.additives.reduce(
        (prev: any, cur: any) => parseFloat(prev) + parseFloat(cur.leftToPay),
        0
      );
    };

    onMounted(totalLeftToPay);

    return { state };
  },
});
